import { createRouter, createWebHistory } from "vue-router";

import store from "@/store";
import { userLinks } from "./user";

const routes = [
  {
    path: "/",
    name: "userLayout",
    component: () => import("@/layout/userLayout.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/student/HomeStudent.vue"),
      },
      ...userLinks,
    ],
  },
  {
    path: "/login",
    name: "authLayout",
    component: () => import("@/layout/authLayout.vue"),
    children: [
      {
        path: "",
        name: "login",
        component: () => import("@/views/LoginPage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  if (store.getters.role && to.meta.role) {
    if (!to.meta.role.includes(store.getters.role)) {
      return { name: from ? from.name : "home" };
    }
  }
});

export default router;

import axios from "axios";
import { ElMessage } from "element-plus";
import cookie from "vue-cookies";
export const helpers = {
  actions: {
    getAxios(context, payload) {
      return axios
        .get(`${context.getters.url}/${payload.url}`, {
          headers: {
            authorization: `Bearer ${cookie.get("admit-token")}`,
          },
          params: payload.search,
        })
        .catch((e) => {
          ElMessage({
            message: e.response.data.message,
            type: "error",
          });
        });
    },
    postAxios(context, payload) {
      return axios
        .post(`${context.getters.url}/${payload.url}`, payload.data, {
          headers: {
            authorization: `Bearer ${cookie.get("admit-token")}`,
          },
        })
        .catch((e) => {
          ElMessage({
            message: e.response.data.message,
            type: "error",
          });
        });
    },
    deleteAxios(context, payload) {
      return axios
        .delete(`${context.getters.url}/${payload}`, {
          headers: {
            authorization: `Bearer ${cookie.get("admit-token")}`,
          },
        })
        .catch((e) => {
          ElMessage({
            message: e.response.data.message,
            type: "error",
          });
        });
    },
    putAxios(context, payload) {
      return axios
        .put(`${context.getters.url}/${payload.url}`, payload.data, {
          headers: {
            authorization: `Bearer ${cookie.get("admit-token")}`,
          },
        })
        .catch((e) => {
          ElMessage({
            message: e.response.data.message,
            type: "error",
          });
        });
    },

    postOtherAxios(context, payload) {
      return axios
        .post(`${context.getters.uri}/${payload.url}`, payload.data, {
          headers: {
            authorization: `Bearer ${cookie.get("admit-token")}`,
          },
        })
        .catch((e) => {
          ElMessage({
            message: e.response.data.message,
            type: "error",
          });
        });
    },
    getOtherAxios(context, payload) {
      return axios
        .get(`${context.getters.uri}/${payload}`, {
          headers: {
            authorization: `Bearer ${cookie.get("admit-token")}`,
          },
        })
        .catch((e) => {
          ElMessage({
            message: e.response.data.message,
            type: "error",
          });
        });
    },
  }, 
};

export const addZero = (val) => {
  return val < 10 ? "0" + val : val;
};

export const convertDate = (date) => {
  let d = new Date(date);
  return date
    ? `${addZero(d.getDate())}/${addZero(
        d.getMonth() + 1
      )}/${d.getFullYear()} ${addZero(d.getHours())}:${addZero(d.getMinutes())}`
    : "";
};

export const convertExamDate = (date) => {
  let d = new Date(date);
  return date
    ? `${addZero(d.getDate())}/${addZero(d.getMonth() + 1)}/${d.getFullYear()}`
    : "";
};

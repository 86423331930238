<template>
  <router-view />
</template>

<script></script>

<style lang="scss">
@import "./styles/reset.scss";
@import "./styles/grid.scss";
@import "./styles/main.scss";
@import "./styles/lib/class.scss";

</style>
